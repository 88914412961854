<nav class="sidebar">
  <div class="sidebar-header">
    <!--- Logo -->
    <a routerLink="/" class="sidebar-brand">S.I.D</a>

    <!--- Toggler -->
    <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div
    class="sidebar-body fancy-scroll"
    (mouseenter)="operSidebarFolded()"
    (mouseleave)="closeSidebarFolded()">
    <!--- Sidemenu start -->

    <ul class="sidebar-nav metismenu" id="sidebar-menu" #sidebarMenu>
      @for (item of menuItems; track $index) {
        <!-- *ngFor="let item of menuItems" -->
        <ng-container>
          <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label }}</li>

          <li class="nav-item" *ngIf="!item.isTitle">
            <a class="nav-link" *ngIf="hasItems(item)">
              @if (item.icon && item?.iconType === ICONSAX) {
                <i [class]="'isax ' + item.icon"></i>
                <span class="link-title sidebar-title-ml-15">{{ item.label }}</span>
              } @else if (item.icon) {
                <span class="link-title">{{ item.label }}</span>
                <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon></i>
              }
              <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
            </a>

            <!-- [routerLink]="item.link"   (click)="goToLink(item)"-->
            <a
              class="nav-link nav-link-ref"
              (click)="goToLink(item)"
              id="{{ item.link }}"
              *ngIf="!hasItems(item)"
              [attr.data-parent]="item.parentId">
              @if (item.icon && item?.iconType === ICONSAX) {
                <i [class]="'isax ' + item.icon"></i>
                <span class="link-title sidebar-title-ml-15">{{ item.label }}</span>
              } @else if (item.icon) {
                <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon></i>
                <span class="link-title">{{ item.label }}</span>
              }
              <span class="badge bg-{{ item.badge.variant }}" *ngIf="item.badge">
                {{ item.badge.text }}
              </span>
            </a>

            <ul
              class="sidebar-nav sub-menu nav-second-level"
              *ngIf="hasItems(item)"
              aria-expanded="false">
              <li
                class="nav-item"
                *ngFor="let subitem of item.subItems"
                [ngClass]="{ 'side-nav-item': hasItems(subitem) }">
                <a class="nav-link side-nav-link-a-ref" *ngIf="hasItems(subitem)">
                  @if (subitem.icon && subitem?.iconType === ICONSAX) {
                    <i [class]="'isax ' + subitem.icon"></i>
                  } @else if (subitem.icon) {
                    <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon></i>
                  }
                  <span class="link-title">{{ subitem.label }}</span>
                  <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
                </a>

                <!-- [routerLink]="subitem.link" -->
                <a
                  class="nav-link nav-link-ref"
                  (click)="goToLink(subitem)"
                  id="{{ subitem.link }}"
                  *ngIf="!hasItems(subitem)"
                  [attr.data-parent]="subitem.parentId">
                  @if (subitem.icon && subitem?.iconType === ICONSAX) {
                    <i [class]="'isax ' + subitem.icon"></i>
                  } @else if (subitem.icon) {
                    <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon></i>
                  }

                  {{ subitem.label }}
                </a>

                <ul
                  class="sidebar-nav sub-menu nav-third-level"
                  *ngIf="hasItems(subitem)"
                  aria-expanded="false">
                  <li class="nav-item" *ngFor="let subSubitem of subitem.subItems">
                    <!-- [routerLink]="subSubitem.link" -->
                    <a
                      class="nav-link nav-link-ref subitems"
                      (click)="goToLink(subSubitem)"
                      id="{{ subSubitem.link }}"
                      [routerLinkActive]="['active']"
                      [attr.data-parent]="subSubitem.parentId">
                      @if (subitem.icon && subitem?.iconType === ICONSAX) {
                        <i [class]="'isax ' + subitem.icon"></i>
                      } @else if (subitem.icon) {
                        <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon></i>
                      }
                      {{ subSubitem.label }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      }
    </ul>

    <!--- Sidemenu end -->
  </div>
  <div class="sidebar-footer">
    <!-- <div class="theme-switch">
      <div class="toggle-switch">
        <input
          type="checkbox"
          class="toggle-switch-input"
          id="themeSwitch"
          (change)="onSidebarThemeChange($event)"
          checked />
        <label class="toggle-switch-label mb-0" for="themeSwitch">
          <span class="check-mark"></span>
          <span>Dark Side Bar</span>
        </label>
      </div>
    </div> -->
    <div class="btm-line d-flex align-items-center gap-4">
      <span class="tx-14">Powered by Ideatolife</span>
      <a routerLink="/" class="footer-logo">
        <img src="assets/images/footer-logo.png" alt="" />
      </a>
    </div>
  </div>
</nav>
<!-- <nav class="settings-sidebar">
  <div class="sidebar-body">
    <a href="" class="settings-sidebar-toggler" (click)="toggleSettingsSidebar($event)">
      <i class="icon feather icon-settings"></i>
    </a>
    <h6 class="text-muted mb-2">Sidebar:</h6>
    <div class="mb-3 pb-3 border-bottom">
      <div class="form-check form-check-inline">
        <label for="" class="form-check-label">
          <input
            type="radio"
            class="form-check-input"
            name="sidebarThemeSettings"
            (change)="onSidebarThemeChange($event)"
            id="sidebarLight"
            value="sidebar-light"
            checked />
          <i class="input-frame"></i>
          Light
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label for="" class="form-check-label">
          <input
            type="radio"
            class="form-check-input"
            name="sidebarThemeSettings"
            (change)="onSidebarThemeChange($event)"
            id="sidebarDark"
            value="sidebar-dark" />
          <i class="input-frame"></i>
          Dark
        </label>
      </div>
    </div>
    <div class="theme-wrapper"></div>
  </div>
</nav> -->

<!-- <li class="nav-item">
        <div class="mb-3 pb-3 border-bottom">
          <div class="form-check form-check-inline">
            <label for="" class="form-check-label">
              <input type="radio" class="form-check-input" name="sidebarThemeSettings" (change)="onSidebarThemeChange($event)" id="sidebarLight" value="sidebar-light" >
              <i class="input-frame"></i>
              <span class="link-title">Light</span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label for="" class="form-check-label">
              <input type="radio" class="form-check-input" name="sidebarThemeSettings" (change)="onSidebarThemeChange($event)" id="sidebarDark" value="sidebar-dark" checked>
              <i class="input-frame"></i>
              <span class="link-title">Dark</span>
            </label>
          </div>
        </div>
      </li> -->
